/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import AddIcon from '../../../app/components/svgIcons/AddIcon';
import CompanyListingTable from './CompanyListingTable';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import { Link } from 'react-router-dom';
import {
  useAddNewCompanyMutation,
  useAddSaveLicenceMutation,
  useChildCompaniesUpdateMutation,
  useGetAllCompaniesQuery,
  useSuspendCompanyMutation,
} from '../companyApiSlice';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import PauseIcon from '../../../app/components/svgIcons/PauseIcon';
import { CSVLink } from 'react-csv';
import OnlineIcon from '../../../app/components/svgIcons/OnlineIcon';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import { STATUSES } from '../../../utils/Statuses';
import AddNewCompanyModal from '../creation/components/AddNewCompanyModal';
import SlashCircleCIon from '../../../app/components/svgIcons/SlashCircleIcon';
import OptionFillIcon from '../../../app/components/svgIcons/OptionFillIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import StyledFilterTabs from '../../../app/components/styledComponents/StyledFilterTabs';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import { toast } from 'react-toastify';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import moment from 'moment';
import { copyText, getStatusFriendly } from '../../../utils/helper';
import NewDownloadIcon from '../../../app/components/svgIcons/NewDownloadIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
// import CampaignDetails from '../../Campaigns/CampaignDetails';
import { Form, Formik } from 'formik';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { getJobLocation } from './companyListinUtil';
import CustomDropdown from '../../../app/components/dropdown/CustomDropDown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import SubsidiariesIcon from '../../../app/components/svgIcons/subsidiariesIcon';
import CrossCircleIcon from '../../../app/components/svgIcons/CrossCircleIcon';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import SubsidiariesModal from '../creation/components/SubsidiariesModal';
import CompanyEditModal from '../creation/components/CompanyEditModal';
import TickCircleIcon from '../../../app/components/svgIcons/TickCircleIcon';
import CopyIcon from '../../../app/components/svgIcons/CopyIcon';

const CompanyListingContainer = () => {
  const [status, setStatus] = useState(STATUSES.NULL);
  const [selectedColumns, setSelectedColumns] = useState(false);
  const [sort, setSort] = useState(false);
  const [filters, setFilters] = useState(false);
  const [addNewCompany, setAddNewCompanyModal] = useState(false);
  const [subsidiariesModal, setSubsidiariesModal] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [companyDetails, setCompanyDetails] = useState();
  const [search, setSearch] = useState('');
  const [companyEdit, setCompanyEdit] = useState(false);
  const [crateNewCompany, { isLoading: loading }] = useAddNewCompanyMutation();
  const [updateChild] = useChildCompaniesUpdateMutation();
  const [addSaveLicence, { isLoading: licenceLoading }] =
    useAddSaveLicenceMutation();
  const [stepCount, setStepCount] = useState(1);
  const [suspendCompany] = useSuspendCompanyMutation();
  const [_values, _setValues] = useState({});

  const createCompany = (values) => {
    if (values?.county) {
      values['county'] = values?.county?.value;
    }
    crateNewCompany(values)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, 'Company created Successfully');
        let date = new Date(values?.startDate);
        let endDate = new Date(values?.endDate);
        const data = {
          compId: res?.company?.id,
          body: {
            licenceId: values?.companyLicense,
            paymentPlanId: values?.paymentPlan,
            startDate: new Date(
              date.getTime() + Math.abs(date.getTimezoneOffset() * 60000),
            ),
            endDate: new Date(
              endDate.getTime() + Math.abs(endDate.getTimezoneOffset() * 60000),
            ),
          },
        };
        addSaveLicence(data)
          .unwrap()
          .then((res) => {
            toast.success(
              res?.message,
              'Licence Created/Activated Successfully',
            );
          });
        setStepCount(1);
        setAddNewCompanyModal(false);
        _setValues({});
        refetch();
      });
  };
  const handleSubsAction = (comp, id) => {
    const payload = {
      id: comp?.id,
      companyName: comp?.name,
      telephone: comp?.telephone,
      careerSiteUrl: comp?.careerSiteUrl,
      statusId: id,
    };
    updateChild(payload)
      .unwrap()
      .then((res) => {
        toast.success(
          `Company ${
            id === STATUSES.DELETE
              ? 'Delete'
              : id === STATUSES.LIVE
              ? 'Active'
              : 'Inactive'
          } Successfully'` || res?.message,
        );
        refetch();
      });
  };
  const statusColor = [
    { statusId: 4, color: 'danger' },
    { statusId: 3, color: 'orange' },
    { statusId: 2, color: 'success' },
    { statusId: 16, color: 'success' },
    { statusId: 1, color: 'info' },
  ];

  const getTagColor = (statusId) => {
    const colour = statusColor?.find((i) => i.statusId === statusId)?.color;
    return colour;
  };

  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };

  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: 'All Companies',
      active: true,
      status: STATUSES.NULL,
    },
    // {
    //   id: 2,
    //   title: 'Standard',
    //   active: false,
    //   status: STATUSES.DRAFT,
    // },
    // {
    //   id: 3,
    //   title: 'Agency',
    //   active: false,
    //   status: STATUSES.CLOSED,
    // }
  ]);

  const [listing, setListing] = useState({ ...initialFilters });
  const [companyFilters, setCompanyFilters] = useState('');

  const onChangeTabs = (tab) => {
    setSearch('');
    setTabs((prevState) =>
      prevState.map((p) => {
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
    setStatus(tabs[tab - 1].status);
    setListing({ ...listing, page: 1, query: '' });
  };
  const {
    data: companyRes,
    refetch,
    error,
    isFetching,
  } = useGetAllCompaniesQuery({
    perPage: listing.perPage,
    page: listing.page,
    query: listing.query && listing.query,
    sort: listing.sortedColumn.path,
    sortDir: listing.sortedColumn.order,
    ...companyFilters,
  });

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };

  const handleSort = (sort) => {
    const sortBlacklist = ['hired', 'statusId', 'account.firstName'];
    if (!sortBlacklist.includes(sort.path)) {
      setListing((prevState) => ({
        ...prevState,
        sortedColumn: { ...sort },
        page: 1,
      }));
    }
  };

  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  const suspndCompany = (id) => {
    suspendCompany({ id: id, statusId: 3 })
      .unwrap()
      .then((res) => {
        toast.success('Company Suspend Successfully' || res?.message);
        refetch();
      });
  };
  const [newColumn, setNewColumn] = useState([]);
  const [column, setColumn] = useState([
    {
      id: 1,
      key: 'name',
      isChecked: true,
      path: 'name',
      label: 'Name',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (comp) => (
        <Link to={`${comp?.id}`} className="text-decoration-none">
          <h5 className="m-0 cut-text"> {comp?.name || '-'}</h5>
          <span className="small cut-text">{comp?.id || '-'}</span>
        </Link>
      ),
    },
    // {
    //   id: 2,
    //   // key: 'postcode',
    //   isChecked: true,
    //   path: 'postcode',
    //   label: 'Location',
    //   content: (comp) => getJobLocation(comp),
    //   alignBody: 'text-left align-middle ps-4 py-3',
    //   alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    // },
    {
      id: 3,
      key: 'licence',
      isChecked: true,
      label: 'Licence',
      content: (comp) => (
        <span className="m-0">
          {comp?.companyLicence[0]?.licence?.name || '-'}
        </span>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 4,
      key: 'type',
      isChecked: true,
      label: 'Type',
      content: (comp) => (
        <span className="m-0">
          {' '}
          {comp?.isParent === 1 ? 'Parent' : 'Subsidiary'}
        </span>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 5,
      key: 'owner',
      isChecked: true,
      label: 'Owner',
      content: (comp) => (
        <span className="m-0">
          {`${
            comp?.account[0]?.firstName || comp?.account[0]?.lastName
              ? `${comp?.account[0]?.firstName || ''} ${
                  comp?.account[0]?.lastName || ''
                }`
              : '-'
          }`}
        </span>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 6,
      key: 'createdAt',
      isChecked: true,
      path: 'createdAt',
      label: 'Created On',
      content: (comp) => (
        <span className="m-0">
          {' '}
          {comp?.createdAt
            ? moment(comp?.createdAt).format('Do MMM YYYY')
            : '-'}
        </span>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 7,
      key: 'statusId',
      isChecked: true,
      path: 'statusId',
      label: 'Status',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (comp) => (
        <div className="d-flex">
          <StyledColoredTag
            className="rounded-pill mx-0"
            variant={getTagColor(comp?.statusId)}
          >
            <OnlineIcon color={getTagColor(comp?.statusId)} className="me-1" />{' '}
            {getStatusFriendly(comp?.statusId) || '-'}
          </StyledColoredTag>
        </div>
      ),
    },
    {
      id: 8,
      key: 'actions',
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle ps-4 py-3 text-nowrap',
      alignHeader: 'align-middle cursor-pointer ps-4 py-3',
      content: (comp) => (
        <div className="d-flex justify-content-center align-items-center">
          <Link
            variant="text"
            className="text-decoration-none  pe-2"
            to={`${comp?.id}`}
          >
            <StyledToolTip
              title="Edit Company"
              className="position-fixed"
              childrenClass={'ms-0 '}
            >
              <EditIcon size={18} />
            </StyledToolTip>
          </Link>
          {comp?.statusId !== 3 && (
            <Link
              variant="text"
              className="text-decoration-none  px-2"
              onClick={() => suspndCompany(comp?.id)}
              to={''}
            >
              <StyledToolTip
                title="Suspend"
                className="position-fixed"
                childrenClass={'ms-0'}
              >
                {' '}
                <SlashCircleCIon />
              </StyledToolTip>
            </Link>
          )}
          <Link
            variant="text"
            className="text-decoration-none feature-pending px-2"
            // to={`${job.id}/job-information`}
            to={''}
          >
            <StyledToolTip
              title="Edit Company"
              className="mt-2"
              placement="top"
            >
              {' '}
              <OptionFillIcon rotate={'left'} />
            </StyledToolTip>
          </Link>
          <div className="d-flex">
            <CustomDropdown
              toggleClassName={'bg-transparent px-0'}
              menuClassName={'adminJobs'}
              dropdownToggle={
                <div className={`account-menu ${'bg-transparent ps-0 ms-2'} `}>
                  <OptionFillIcon rotate={'left'} />
                </div>
              }
              dropdownMenu={
                <>
                  {comp?.isParent === 1 && (
                    <DropdownItem
                      className="py-1"
                      onClick={() => {
                        setSubsidiariesModal(true);
                        setCompanyId(comp);
                      }}
                    >
                      <span className="dark-text ">
                        <SubsidiariesIcon size={'18'} />
                      </span>
                      <span className="ms-3 text-secondary">
                        View Subsidiaries
                      </span>
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="py-1"
                    onClick={() => handleSubsAction(comp, STATUSES.DELETE)}
                  >
                    <span className="dark-text pb-2">
                      <TrashIcon />
                    </span>
                    <span className="ms-3 text-secondary pb-2">Delete</span>
                  </DropdownItem>
                  <DropdownItem
                    className="py-1"
                    onClick={() =>
                      handleSubsAction(
                        comp,
                        comp?.statusId === STATUSES.INACTIVE
                          ? STATUSES.LIVE
                          : STATUSES.INACTIVE,
                      )
                    }
                  >
                    <span className="dark-text pb-2">
                      {comp?.statusId === STATUSES.INACTIVE ? (
                        <TickCircleIcon />
                      ) : (
                        <CrossCircleIcon />
                      )}
                    </span>
                    <span className="ms-3 text-secondary pb-2">
                      {comp?.statusId === STATUSES.INACTIVE
                        ? 'Mark as Active'
                        : 'Mark as Inactive'}
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    className="py-1"
                    onClick={() =>
                      copyText(
                        `https://api2.smartrecruitonline.com/feeds/indeed-organic/${comp?.id}`,
                      ).then((res) => toast.success('The Indeed URL has been copied to your clipboard'))
                    }
                  >
                    <span className="dark-text pb-2">
                      <CopyIcon />
                    </span>
                    <span className="ms-3 ps-1 text-secondary pb-2">
                      Copy Indeed URL
                    </span>
                  </DropdownItem>
                </>
              }
            />
          </div>
        </div>
      ),
    },
  ]);

  const handleCHeck = (item) => {
    const tempColumn = [...column];
    const newItemIndex = column.findIndex((t) => t.id === item.id);
    tempColumn[newItemIndex].isChecked = !tempColumn[newItemIndex].isChecked;
    setColumn(tempColumn);
  };

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCsvData = () => {
    return (
      companyRes?.companies?.map((cmp) => ({
        id: cmp?.id || '-',
        name: cmp?.name || '-',
        location: cmp?.postcode || '-',
        licence: cmp?.companyLicence[0]?.licence?.name || '-',
        type: cmp?.companyType || 'Standard',
        owner:
          cmp?.account[0]?.firstName + ' ' + cmp?.account[0]?.lastName || '-',
        created: cmp?.createdAt
          ? moment(cmp?.createdAt).format('Do MMM YYYY')
          : '-',
        status: Object.keys(STATUSES).find(
          (k) => STATUSES[k] === cmp?.statusId,
        ),
      })) || []
    );
  };
  const ref = useRef();

  const filterRef = useRef(null);
  const sortFilterRef = useRef(null);
  const selectColumnRef = useRef(null);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSort(false);
          setSelectedColumns(false);
          setFilters(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(
    (filters && filterRef) ||
      (sort && sortFilterRef) ||
      (selectedColumns && selectColumnRef),
  );

  const handleSubmit = (values) => {
    setApplyedValues({ ...values });
    setCompanyFilters((prevFilters) => ({
      ...prevFilters,
      ...values,
    }));
    setFilters(false);
  };
  const [appliedValues, setApplyedValues] = useState({});

  return (
    <div>
      <Portlet>
        <PortletHeader
          tabs={tabs}
          title="Companies"
          onChangeTabs={onChangeTabs}
          subTitle={`(${companyRes?.totalCount || 0})`}
          actions={
            <div className="flex-center">
              <StyledButton
                className="btn btn-primary"
                icon={<AddIcon />}
                onClick={() => setAddNewCompanyModal(true)}
              >
                Add New
              </StyledButton>
            </div>
          }
        />
        <div className="portlet__body-bg">
          <PortletBody>
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search Company Name"
                  />
                </div>
                <div className="d-flex">
                  <div
                    className="flex-center mx-2
                "
                  >
                    <StyledFilterTabs>
                      <div className="btn-group " role="group">
                        <button
                          className="btn "
                          onClick={() => setFilters(!filters)}
                        >
                          <FilterIcon className="mx-1" /> Filters
                        </button>
                      </div>
                    </StyledFilterTabs>
                  </div>
                  <div className="flex-center">
                    <StyledFilterTabs>
                      <div className="btn-group " role="group">
                        <button
                          className="btn feature-pending"
                          id="sort"
                          onClick={() => setSort(!sort)}
                        >
                          <SortIcon size={15} />
                        </button>
                        <button
                          className="btn"
                          onClick={() => setSelectedColumns(!selectedColumns)}
                        >
                          <PauseIcon size={15} />
                        </button>

                        <button className="btn">
                          <CSVLink
                            data={getCsvData()}
                            filename={'companies.csv'}
                          >
                            <StyledToolTip
                              title="Download"
                              childrenClass={'ms-0'}
                            >
                              <NewDownloadIcon size={15} />{' '}
                            </StyledToolTip>
                          </CSVLink>
                        </button>
                      </div>
                    </StyledFilterTabs>
                  </div>
                </div>
              </div>
              <div className="position-relative d-flex rounded flex-column">
                <div ref={filterRef} className="bg-transparent mt-2">
                  {filters && (
                    <StyledFilterBar width="400px" variant="filter">
                      <div className="d-flex justify-content-end">
                        <div
                          className="cursor-pointer mt-3 me-3"
                          onClick={() => setFilters(false)}
                        >
                          <CrossIcon />
                        </div>
                      </div>

                      <div className="sd-header">
                        <h4 className="mb-0">Filters</h4>
                        {!isEqual(companyFilters, initialFilters) && (
                          <h6
                            className="mb-0 cursor-pointer"
                            onClick={() => {
                              setApplyedValues({});
                              setCompanyFilters((prevFilters) => ({
                                sort: prevFilters?.sort ?? 'createdAt',
                                sortDir: prevFilters?.sortDir ?? 'DESC',
                              }));
                              setFilters(false);
                            }}
                          >
                            Clear All
                          </h6>
                        )}
                      </div>
                      <div className="sd-body">
                        <Formik
                          onSubmit={handleSubmit}
                          initialValues={{
                            ...appliedValues,
                          }}
                        >
                          {({ handleSubmit, values, submitForm, errors }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className="col-12">
                                <div className="mb-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Status
                                  </label>
                                  <FormikSelect
                                    name="status"
                                    id="status"
                                    options={[
                                      {
                                        label: 'Deleted',
                                        value: STATUSES.DELETE,
                                      },
                                      { label: 'Live', value: STATUSES.LIVE },
                                      {
                                        label: 'Pending',
                                        value: STATUSES.PENDING,
                                      },
                                      {
                                        label: 'Suspended',
                                        value: STATUSES.SUSPENDED,
                                      },
                                    ]}
                                    placeholder="Choose Status"
                                  />
                                </div>
                              </div>

                              <StyledBtn>
                                <StyledButton
                                  type="button"
                                  onClick={handleSubmit}
                                  className="ms-auto w-100"
                                  loading={loading}
                                  disabled={loading}
                                >
                                  Apply Filters
                                </StyledButton>
                              </StyledBtn>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </StyledFilterBar>
                  )}
                </div>
              </div>
              <div
                className=" position-relative  d-flex rounded flex-column"
                ref={sortFilterRef}
              >
                {sort && (
                  <StyledFilterBar className="bg-white p-3 align-items-center justify-content-center">
                    Sort
                  </StyledFilterBar>
                )}
              </div>
              <div className=" position-relative  d-flex rounded flex-column">
                {selectedColumns && (
                  <StyledFilterBar
                    className="bg-white p-3 align-items-center justify-content-center my-3"
                    ref={selectColumnRef}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center mb-3"
                      onClick={() => setSelectedColumns(false)}
                    >
                      <h5 className="mb-0 me-2">Customise Columns</h5>
                      <div className="ms-2 cursor-pointer">
                        <CrossIcon />
                      </div>
                    </div>
                    {column.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center py-2 px-2"
                          key={`cl-${index}`}
                        >
                          <StyledCheckbox
                            value={item.isChecked}
                            onChange={() => handleCHeck(item)}
                          />
                          <h6 className="ms-3 mb-0">{item.label}</h6>
                        </div>
                      );
                    })}
                    <div className="my-3 w-100 d-flex">
                      <StyledButton
                        onClick={handleSelectedColumn}
                        className="w-100"
                      >
                        Apply
                      </StyledButton>
                    </div>
                  </StyledFilterBar>
                )}
              </div>
              {error || companyRes?.companies?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  No Companies Found ...
                </div>
              ) : (
                <>
                  {companyRes?.companies?.length > 0 ? (
                    <CompanyListingTable
                      companies={companyRes?.companies || []}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={newColumn}
                    />
                  ) : (
                    <TableSkeleton
                      cols={[
                        'Name',
                        // 'Location',
                        'Licence',
                        'Type',
                        'Owner',
                        'Created',
                        'Status',
                        'Actions',
                      ]}
                      rows={3}
                    />
                  )}
                  <PaginationPerPage
                    perPage={companyRes?.perPage || []}
                    pageNo={companyRes?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={companyRes?.totalCount || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </>
          </PortletBody>
        </div>
      </Portlet>
      <>
        {' '}
        <AddNewCompanyModal
          show={addNewCompany}
          onHide={() => {
            setAddNewCompanyModal(false);
            setStepCount(1);
          }}
          createCompany={createCompany}
          stepCount={stepCount}
          setStepCount={setStepCount}
          loading={loading}
          _values={_values}
          _setValues={_setValues}
        />
        <SubsidiariesModal
          show={subsidiariesModal}
          onHide={() => {
            setSubsidiariesModal(false);
          }}
          companyId={companyId}
          setSubsidiariesModal={setSubsidiariesModal}
          setCompanyDetails={setCompanyDetails}
          setCompanyEdit={setCompanyEdit}
        />
        <CompanyEditModal
          show={companyEdit}
          onHide={() => {
            setCompanyEdit(false);
          }}
          companyDetails={companyDetails}
          companyEdit={companyEdit}
          refetch={refetch}
        />
      </>
    </div>
  );
};
export default CompanyListingContainer;
const StyledBtn = styled.div`
  margin-bottom: 2rem !important;
`;
