import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import AdminInfoForm from './AdminInfoForm';
import ConfigurationForm from './ConfigurationForm';
import GeneralInfoForm from './GeneralInfoForm';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import moment from 'moment';

const AddNewCompanyModal = ({
  show,
  onHide,
  createCompany,
  stepCount,
  setStepCount,
  loading,
  _values,
  _setValues
}) => {

  const adminInfoSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    phoneCountryCode: Yup.string().required('Required'),
    phoneNumber: Yup.string().when('phoneCountryCode', (phoneCountryCode, schema) => schema.required('Required').test('is-valid-phone', 'Invalid Number', (value) => findPhoneNumbersInText(`${phoneCountryCode}${value}`)?.length > 0 && !value?.includes('+'))),
  });
  const generalInfoSchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    // town: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    telephoneCode: Yup.string().required('Required'),
    telephone: Yup.string().when('telephoneCode', (telephoneCode, schema) => schema.required('Required').test('is-valid-phone', 'Invalid Number', (value) => findPhoneNumbersInText(`${telephoneCode}${value}`)?.length > 0 && !value?.includes('+'))),
  });
  const occyInitalInfoSchema = Yup.object().shape({
    // companyType: Yup.string().required('Required'),
    companyLicense: Yup.object().required('Required'),
    paymentPlan: Yup.object().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required').when('startDate', (startDate, schema) => {
      return startDate
        ? schema.min(
          moment(startDate).add(1, 'days'),
          'End date must be after the start date',
        )
        : schema;
    }),
    companySize: Yup.string().required('Required'),
    hubspotId: Yup.string().required('Required'),
    contractSigned: Yup.string().required('Required'),
    // jobPostingFree: Yup.string().required('Required'),
    // jobPostingPremium: Yup.string().required('Required'),
    industry: Yup.object().required('Required'),
  });

  const adminInfoInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneCountryCode: '+44'
  };
  const generalInfoInitialValues = {
    companyName: '',
    googleLocation: undefined,
    address: '',
    town:'',
    postcode: '',
    country: undefined,
    telephone: '',
    telephoneCode: '+44'
  };
  const occyInitialInfoInitialValues = {
    companyType: 'Standard',
    companyLicense: undefined,
    paymentPlan: undefined,
    startDate: undefined,
    endDate: undefined,
    companySize: '',
    hubspotId: '',
    industry: undefined,
    jobPostingFree: 'MANUAL',
    jobPostingPremium: 'MANUAL',
    contractSigned:''
  };

  const validationSchema = () => {
    switch (stepCount) {
      case 1:
        return adminInfoSchema;
      case 2:
        return generalInfoSchema;
      case 3:
        return occyInitalInfoSchema;
      default:
        return adminInfoSchema;
    }
  };

  const getInitialValues = () => {
    switch (stepCount) {
      case 1:
        return adminInfoInitialValues;
      case 2:
        return generalInfoInitialValues;
      case 3:
        return occyInitialInfoInitialValues;
      default:
        return adminInfoInitialValues;
    }
  };

  const handleSubmit = (values) => {
    let data = { ...values };
    setStepCount(stepCount !== 3 ? stepCount + 1 : stepCount);
    _setValues((prevState) => ({ ...prevState, ...values }));
    if (stepCount === 3) {
      data.jobPostingFree = 'MANUAL';
      data.jobPostingPremium = 'MANUAL';
      data.industry = data?.industry?.value;
      data.companyLicense = data?.companyLicense?.value?.id;
      data.paymentPlan = data?.paymentPlan?.id;
      data.phoneNumber = data?.phoneNumber && data?.phoneCountryCode + (data?.phoneNumber[0] === '0' ? data?.phoneNumber.substring(1) : data?.phoneNumber);
      data.telephone = data?.telephone && data?.telephoneCode + (data?.telephone[0] === '0' ? data?.telephone.substring(1) : data?.telephone);
      data.phoneCountryCode = undefined;
      data.telephoneCode = undefined;
      createCompany(data);
    }
  };

  const backCount = () => {
    setStepCount(stepCount !== 1 ? stepCount - 1 : stepCount);
  };
  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => { _setValues({}); onHide() }}
        size={'lg'}
        className="bg-black bg-opacity-75"
      >
        <Formik
          initialValues={{ ...getInitialValues(), ..._values }}
          enableReinitialize
          validationSchema={validationSchema()}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue, values, ...props }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header
                // title="Add Companies"
                closeButton
                className="border-bottom-0 pb-0 align-items-baseline me-3 w-100"
              >
                <div>
                  <Modal.Title
                    as="h4"
                    className="flex-grow-1 px-3 pt-3 cursor-pointer flex-center fs-3 mb"
                  >
                    Add New Company
                  </Modal.Title>
                  <p className={`px-3`}>{`Step ${stepCount} of 3`}</p>
                </div>
              </Modal.Header>

              <Modal.Body className="d-flex  flex-column bg-body rounded ">
                {stepCount === 1 && <AdminInfoForm />}
                {stepCount === 2 && <GeneralInfoForm setFieldValue={setFieldValue}/>}
                {stepCount === 3 && (
                  <ConfigurationForm
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )}
                <div
                  className={`${stepCount === 1
                    ? 'justify-content-end'
                    : 'justify-content-between'
                    } d-flex  mt-3`}
                >
                  {stepCount > 1 && (
                    <StyledButton
                      variant="white"
                      className={`mb-2 me-0 me-lg-3`}
                      onClick={() => backCount()}
                    >
                      Back
                    </StyledButton>
                  )}
                  <div className="d-flex gap-3">
                    <StyledButton
                      variant={'white'}
                      className="mb-2 border-0 fw-bold"
                      onClick={() => { _setValues({}); onHide() }}
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      className="mb-2"
                      type="submit"
                      disabled={loading}
                      loading={loading}
                    >
                      {stepCount !== 3 ? 'Next' : 'Save'}
                    </StyledButton>
                  </div>
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default AddNewCompanyModal;
