
import { isEqual } from "lodash";
import IndeedBlueIcon from "../../app/components/svgIcons/IndeedBlueIcon";
import LinkedInImageIcon from "../../app/components/svgIcons/LinkedInImageIcon";
import PostingDetailIcon from "../../app/components/svgIcons/PostingDetailIcon";
import SummaryIcon from "../../app/components/svgIcons/SummaryIcon";

export const sort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Name - A to Z',
    value: 'campaignName',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Name - Z to A',
    value: 'campaignName',
    dir: 'DESC',
    match: "checked3"
  },
  {
    id: 4,
    label: 'Start Date - Oldest to Latest',
    value: 'startDate',
    dir: 'ASC',
    match: "checked4"
  },
  {
    id: 5,
    label: 'Start Date - Latest to Oldest',
    value: 'startDate',
    dir: 'DESC',
    match: "checked5"
  },

];

export const campaignStatus = [
  {
    label: 'PENDING',
    value: 1,
  }, {
    label: 'LIVE',
    value: 2,
  }, {
    label: 'CLOSED',
    value: 21,
  },
  {
    label: 'SCHEDULED',
    value: 63,
  },
  {
    label: 'SUSPENDED',
    value: 3,
  },
  {
    label: 'DRAFT',
    value: 6,
  },
  {
    label: 'FAILED',
    value: 19,
  }
];

export const Jobsort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Created (Oldest to Newest)',
    value: 'createdAt',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Created (Newest to Oldest)',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked3"
  },
  {
    id: 4,
    label: 'Name (A to Z)',
    value: 'title',
    dir: 'ASC',
    match: "checked4"
  },
  {
    id: 5,
    label: 'Name (Z to A)',
    value: 'title',
    dir: 'DESC',
    match: "checked5"
  },
];
export const sidebar = [
  {
    title: 'Posting Details',
    number: 1,
    icon: <PostingDetailIcon className={'me-2'} size={18} />,
  },
  {
    title: 'Summary',
    number: 2,
    icon: <SummaryIcon className={'me-2'} size={18} />,
  },

];
export const jobBoardsArray = [
  { id: 1, icon: <LinkedInImageIcon />, name: "LinkedIn", cost: "£15", accesskey: "11" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "12" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "13" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Reed", cost: "£15", accesskey: "16" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "18" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "19" },
  { id: 1, icon: <LinkedInImageIcon />, name: "indeed", cost: "£15", accesskey: "12" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "12" }
];

export const salaryRates = [
  {
    label: 'Annual Salary',
    value: 'Annual Salary',
  },
  {
    label: 'Daily Rate',
    value: 'Daily Rate',
  },
  {
    label: 'Hourly Rate',
    value: 'Hourly Rate',
  }

];
export const jobBoardAccessKeys = {
  CV_LIBRARY: "OCCY_CV_LIBRARY",
  REED: "OCCY_REED",
  NHS: "OCCY_NHS",
  MONSTER: "OCCY_MONSTER",
  TOTAL_JOBS: "OCCY_TOTAL_JOBS",
  GUM_TREE: "OCCY_GUM_TREE",
}

export const jobBoardAccessKe = {
  CV_LIBRARY: "CV_LIBRARY",
  REED: "REED",
  NHS: "NHS",
  MONSTOR: "MONSTER",
  TOTAL_JOBS: "TOTAL_JOBS",
  GUM_TREE: "GUM_TREE",
}
export const advertKeys = {
  FAILED: 0,
  PENDING: 1,
  ACTIVE: 2,
  REJECTED: 3,
  DELETED: 4,
}
export const industries = [
  { label: 'Accounting/Financial/Insurance', value: 'Accounting/Financial/Insurance' },
  { label: 'Administration', value: 'Administration' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Arts/Graphic Design', value: 'Arts/Graphic Design' },
  { label: 'Automotive/Aerospace', value: 'Automotive/Aerospace' },
  { label: 'Catering', value: 'Catering' },
  { label: 'Charity', value: 'Charity' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Customer Services', value: 'Customer Services' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Education', value: 'Education' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Hospitality/Hotel', value: 'Hospitality/Hotel' },
  { label: 'IT', value: 'IT' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Leisure/Tourism', value: 'Leisure/Tourism' },
  { label: 'Management', value: 'Management' },
  { label: 'Manufacturing/Surveying', value: 'Manufacturing/Surveying' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Media', value: 'Media' },
  { label: 'Medical/Pharmaceutical/Scientific', value: 'Medical/Pharmaceutical/Scientific' },
  { label: 'Military/Emergency/Government', value: 'Military/Emergency/Government' },
  { label: 'Personnel/Recruitment', value: 'Personnel/Recruitment' },
  { label: 'Property Services', value: 'Property Services' },
  { label: 'Public Sector', value: 'Public Sector' },
  { label: 'Retail/Purchasing', value: 'Retail/Purchasing' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Social Care', value: 'Social Care' },
  { label: 'Telecoms', value: 'Telecoms' },
  { label: 'Other', value: 'Other' },
];
export const quickapply = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }];

export const coverLetter = [
  { label: 'Optional', value: 'OPTIONAL' },
  { label: 'Required', value: 'REQUIRED' },
  { label: 'Not Required', value: 'NOT_REQUIRED' },
];
export const CLICKADVERTPRODUCTICONS = {

  'INDEED_SPONSORED': <IndeedBlueIcon width={44} size={40} />
}
export const hiddenSalary = [
  { label: 'Negotiable', value: 'Negotiable' },
  { label: 'Commision only', value: 'Commision only' },
  { label: 'Competitive', value: 'Competitive' }
];
export const PostingSortItem = [
  {
    id: 1,
    label: 'None',
    value: 'requestedOn',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Requested on (Oldest to Newest)',
    value: 'requestedOn',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Requested (Newest to Oldest)',
    value: 'requestedOn',
    dir: 'DESC',
    match: "checked3"
  },
];

export const productTypes = [
  {
    label: 'Standard job',
    value: 'Standard job',
  },
  {
    label: 'Premium job',
    value: 'Premium job',
  },
  {
    label: 'Featured job',
    value: 'Featured job',
  },
  {
    label: 'Premium + job',
    value: 'Premium + job',
  },
  {
    label: 'Marketplace job',
    value: 'Marketplace job',
  }

];

export const parentSector = [
  {
    label: 'Accountancy',
    value: 'Accountancy',
  },
  {
    label: 'Accountancy (Qualified)',
    value: 'Accountancy (Qualified)',
  },
  {
    label: 'Admin, Secretarial & PA',
    value: 'Admin, Secretarial & PA',
  },
  {
    label: 'Apprenticeships',
    value: 'Apprenticeships',
  },
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Charity & Voluntary',
    value: 'Charity & Voluntary',
  },
  {
    label: 'Construction & Property',
    value: 'Construction & Property',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Energy',
    value: 'Energy',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Estate Agency',
    value: 'Estate Agency',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'FMCG',
    value: 'FMCG',
  },
  {
    label: 'General Insurance',
    value: 'General Insurance',
  },
  {
    label: 'Graduate Roles',
    value: 'Graduate Roles',
  },
  {
    label: 'Health & Medicine',
    value: 'Health & Medicine',
  },
  {
    label: 'Hospitality & Catering',
    value: 'Hospitality & Catering',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'IT & Telecoms',
    value: 'IT & Telecoms',
  },
  {
    label: 'Legal',
    value: 'Legal',
  },
  {
    label: 'Leisure & Tourism',
    value: 'Leisure & Tourism',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Marketing & PR',
    value: 'Marketing & PR',
  },
  {
    label: 'Media, Digital & Creative',
    value: 'Media, Digital & Creative',
  },
  {
    label: 'Motoring & Automotive',
    value: 'Motoring & Automotive',
  },
  {
    label: 'Purchasing',
    value: 'Purchasing',
  },
  {
    label: 'Recruitment Consultancy',
    value: 'Recruitment Consultancy',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Scientific',
    value: 'Scientific',
  },
  {
    label: 'Security & Safety',
    value: 'Security & Safety',
  },
  {
    label: 'Social Care',
    value: 'Social Care',
  },
  {
    label: 'Strategy & Consultancy',
    value: 'Strategy & Consultancy',
  },
  {
    label: 'Transport & Logistics',
    value: 'Transport & Logistics',
  },
];

export const inventoryType = [
  { label: 'Transactional', value: 'Transactional' },
  { label: 'Slotted', value: 'Slotted' },
  { label: 'AreaWideTransactional', value: 'AreaWideTransactional' },
  { label: 'AreaWideSlotted', value: 'AreaWideSlotted' },
];

export const jobLevel = [
  { label: 'Student (High School)', value: 'Student (High School)' },
  { label: 'Student (Undergraduate/Graduate)', value: 'Student (Undergraduate/Graduate)' },
  { label: 'Entry Level', value: 'Entry Level' },
  { label: 'Experienced (Non-Manager)', value: 'Experienced (Non-Manager)' },
  { label: 'Manager (Manager/Supervisor of Staff)', value: 'Manager (Manager/Supervisor of Staff)' },
  { label: 'Executive (SVP, VP, Department Head, etc)', value: 'Executive (SVP, VP, Department Head, etc)' },
  { label: 'Senior Executive (President, CFO, etc)', value: 'Senior Executive (President, CFO, etc)' },
];

export const workArea = [
  {
      "label": "Accident and Emergency",
      "value": "Accident and Emergency"
  },
  {
      "label": "Accident and Emergency Streaming",
      "value": "Accident and Emergency Streaming"
  },
  {
      "label": "Acute Internal Medicine",
      "value": "Acute Internal Medicine"
  },
  {
      "label": "Administration",
      "value": "Administration"
  },
  {
      "label": "Allergy",
      "value": "Allergy"
  },
  {
      "label": "Ambulance Services",
      "value": "Ambulance Services"
  },
  {
      "label": "Anaesthetics",
      "value": "Anaesthetics"
  },
  {
      "label": "Anatomical Pathology",
      "value": "Anatomical Pathology"
  },
  {
      "label": "Andrology",
      "value": "Andrology"
  },
  {
      "label": "Angiography",
      "value": "Angiography"
  },
  {
      "label": "Art Therapy",
      "value": "Art Therapy"
  },
  {
      "label": "Art/Music/Dramatherapy",
      "value": "Art/Music/Dramatherapy"
  },
  {
      "label": "Audiological Science",
      "value": "Audiological Science"
  },
  {
      "label": "Audio Vestibular Medicine",
      "value": "Audio Vestibular Medicine"
  },
  {
      "label": "Audit",
      "value": "Audit"
  },
  {
      "label": "Autonomic Science",
      "value": "Autonomic Science"
  },
  {
      "label": "Aviation and Space Medicine",
      "value": "Aviation and Space Medicine"
  },
  {
      "label": "Bank",
      "value": "Bank"
  },
  {
      "label": "Behavioural Sciences",
      "value": "Behavioural Sciences"
  },
  {
      "label": "Biomechanical Engineering",
      "value": "Biomechanical Engineering"
  },
  {
      "label": "Blood Sciences",
      "value": "Blood Sciences"
  },
  {
      "label": "Blood Transfusion",
      "value": "Blood Transfusion"
  },
  {
      "label": "Breast Screening",
      "value": "Breast Screening"
  },
  {
      "label": "Breast Surgery",
      "value": "Breast Surgery"
  },
  {
      "label": "Building Services",
      "value": "Building Services"
  },
  {
      "label": "Burns Care",
      "value": "Burns Care"
  },
  {
      "label": "Cancer Clinical Genetics",
      "value": "Cancer Clinical Genetics"
  },
  {
      "label": "Cancer Genomics",
      "value": "Cancer Genomics"
  },
  {
      "label": "Cancer Molecular Genetics",
      "value": "Cancer Molecular Genetics"
  },
  {
      "label": "Cancer Support",
      "value": "Cancer Support"
  },
  {
      "label": "Cardiac Physiology",
      "value": "Cardiac Physiology"
  },
  {
      "label": "Cardiac, Vascular, Respiratory and Sleep Sciences",
      "value": "Cardiac, Vascular, Respiratory and Sleep Sciences"
  },
  {
      "label": "Cardiology",
      "value": "Cardiology"
  },
  {
      "label": "Cardio-thoracic Surgery",
      "value": "Cardio-thoracic Surgery"
  },
  {
      "label": "Catering",
      "value": "Catering"
  },
  {
      "label": "Cellular Sciences",
      "value": "Cellular Sciences"
  },
  {
      "label": "Cervical Cytology",
      "value": "Cervical Cytology"
  },
  {
      "label": "Chaplaincy",
      "value": "Chaplaincy"
  },
  {
      "label": "Chemical Pathology",
      "value": "Chemical Pathology"
  },
  {
      "label": "Child and Adolescent Psychiatry",
      "value": "Child and Adolescent Psychiatry"
  },
  {
      "label": "Child Protection",
      "value": "Child Protection"
  },
  {
      "label": "Chiropody/Podiatry",
      "value": "Chiropody/Podiatry"
  },
  {
      "label": "Clinical Audit",
      "value": "Clinical Audit"
  },
  {
      "label": "Clinical Biochemistry",
      "value": "Clinical Biochemistry"
  },
  {
      "label": "Clinical Bioinformatics",
      "value": "Clinical Bioinformatics"
  },
  {
      "label": "Clinical Cytogenetics",
      "value": "Clinical Cytogenetics"
  },
  {
      "label": "Clinical Cytogenetics and Molecular Genetics",
      "value": "Clinical Cytogenetics and Molecular Genetics"
  },
  {
      "label": "Clinical Embryology",
      "value": "Clinical Embryology"
  },
  {
      "label": "Clinical Engineering",
      "value": "Clinical Engineering"
  },
  {
      "label": "Clinical Genetics",
      "value": "Clinical Genetics"
  },
  {
      "label": "Clinical Governance",
      "value": "Clinical Governance"
  },
  {
      "label": "Clinical Haematology",
      "value": "Clinical Haematology"
  },
  {
      "label": "Clinical Informatics",
      "value": "Clinical Informatics"
  },
  {
      "label": "Clinical Measurement",
      "value": "Clinical Measurement"
  },
  {
      "label": "Clinical Neurophysiology",
      "value": "Clinical Neurophysiology"
  },
  {
      "label": "Clinical Oncology",
      "value": "Clinical Oncology"
  },
  {
      "label": "Clinical Perfusion",
      "value": "Clinical Perfusion"
  },
  {
      "label": "Clinical Pharmaceutical Science",
      "value": "Clinical Pharmaceutical Science"
  },
  {
      "label": "Clinical Pharmacology and Therapeutics",
      "value": "Clinical Pharmacology and Therapeutics"
  },
  {
      "label": "Clinical Physiology",
      "value": "Clinical Physiology"
  },
  {
      "label": "Clinical Support",
      "value": "Clinical Support"
  },
  {
      "label": "Communication",
      "value": "Communication"
  },
  {
      "label": "Community Gynaecology",
      "value": "Community Gynaecology"
  },
  {
      "label": "Community Health Services",
      "value": "Community Health Services"
  },
  {
      "label": "Community Sexual and Reproductive Health",
      "value": "Community Sexual and Reproductive Health"
  },
  {
      "label": "Complementary Medicine/Therapy",
      "value": "Complementary Medicine/Therapy"
  },
  {
      "label": "Corporate",
      "value": "Corporate"
  },
  {
      "label": "Counselling",
      "value": "Counselling"
  },
  {
      "label": "Critical Care",
      "value": "Critical Care"
  },
    {
        "label": "CT",
        "value": "CT"
    },
    {
        "label": "Cytopathology",
        "value": "Cytopathology"
    },
    {
        "label": "Day Case Unit",
        "value": "Day Case Unit"
    },
    {
        "label": "Decontamination Science",
        "value": "Decontamination Science"
    },
    {
        "label": "Dental and Maxillofacial Radiology",
        "value": "Dental and Maxillofacial Radiology"
    },
    {
        "label": "Dental Technology",
        "value": "Dental Technology"
    },
    {
        "label": "Dental/Oral",
        "value": "Dental/Oral"
    },
    {
        "label": "Dermatology",
        "value": "Dermatology"
    },
    {
        "label": "Dermatopathology Reporting",
        "value": "Dermatopathology Reporting"
    },
    {
        "label": "Diabetes",
        "value": "Diabetes"
    },
    {
        "label": "Digital and Data -- Architecture",
        "value": "Digital and Data -- Architecture"
    },
    {
        "label": "Digital and Data -- Cyber Security",
        "value": "Digital and Data -- Cyber Security"
    },
    {
        "label": "Digital and Data -- Data and Analytics",
        "value": "Digital and Data -- Data and Analytics"
    },
    {
        "label": "Digital and Data -- Education, Training and Dev",
        "value": "Digital and Data -- Education, Training and Dev"
    },
    {
        "label": "Digital and Data -- Information Governance",
        "value": "Digital and Data -- Information Governance"
    },
    {
        "label": "Digital and Data -- IT Operations",
        "value": "Digital and Data -- IT Operations"
    },
    {
        "label": "Digital and Data -- Product and Delivery",
        "value": "Digital and Data -- Product and Delivery"
    },
    {
        "label": "Digital and Data -- Quality Assurance Testing",
        "value": "Digital and Data -- Quality Assurance Testing"
    },
    {
        "label": "Digital and Data -- Software Development",
        "value": "Digital and Data -- Software Development"
    },
    {
        "label": "Digital and Data -- Strategy and Development",
        "value": "Digital and Data -- Strategy and Development"
    },
    {
        "label": "Digital and Data -- User Centred Design",
        "value": "Digital and Data -- User Centred Design"
    },
    {
        "label": "Digital, Data and Technology",
        "value": "Digital, Data and Technology"
    },
    {
        "label": "Diagnostic Neuropathology",
        "value": "Diagnostic Neuropathology"
    },
    {
        "label": "Diagnostic and Interventional Radiology",
        "value": "Diagnostic and Interventional Radiology"
    },
    {
        "label": "Diagnostic Radiology Support",
        "value": "Diagnostic Radiology Support"
    },
    {
        "label": "Dietetics",
        "value": "Dietetics"
    },
    {
        "label": "Dining Room",
        "value": "Dining Room"
    },
    {
        "label": "District Nursing",
        "value": "District Nursing"
    },
    {
        "label": "Domestic Services",
        "value": "Domestic Services"
    },
    {
        "label": "Dramatherapy",
        "value": "Dramatherapy"
    },
    {
        "label": "Education",
        "value": "Education"
    },
    {
        "label": "Elderly Care Medicine",
        "value": "Elderly Care Medicine"
    },
    {
        "label": "Electron Microscopy",
        "value": "Electron Microscopy"
    },
    {
        "label": "Electronics and Biomedical Engineering",
        "value": "Electronics and Biomedical Engineering"
    },
    {
        "label": "Embryology",
        "value": "Embryology"
    },
    {
        "label": "Emergency Control",
        "value": "Emergency Control"
    },
    {
        "label": "Emergency Services",
        "value": "Emergency Services"
    },
    {
        "label": "Endocrinology and Diabetes Mellitus",
        "value": "Endocrinology and Diabetes Mellitus"
    },
    {
        "label": "Endodontics",
        "value": "Endodontics"
    },
    {
        "label": "Endoscopy",
        "value": "Endoscopy"
    },
    {
        "label": "Engineering",
        "value": "Engineering"
    },
    {
        "label": "Environmental Sciences",
        "value": "Environmental Sciences"
    },
    {
        "label": "Estates",
        "value": "Estates"
    },
    {
        "label": "Estates Electrical Engineering",
        "value": "Estates Electrical Engineering"
    },
    {
        "label": "Estates Mechanical Engineering",
        "value": "Estates Mechanical Engineering"
    },
    {
        "label": "Facilities",
        "value": "Facilities"
    },
    {
        "label": "Family Nurse Partnership",
        "value": "Family Nurse Partnership"
    },
    {
        "label": "Field Epidemiology",
        "value": "Field Epidemiology"
    },
    {
        "label": "Finance",
        "value": "Finance"
    },
    {
        "label": "Financial Management",
        "value": "Financial Management"
    },
    {
        "label": "Financial Services",
        "value": "Financial Services"
    },
    {
        "label": "Forensic Histopathology",
        "value": "Forensic Histopathology"
    },
    {
        "label": "Forensic Pathology",
        "value": "Forensic Pathology"
    },
    {
        "label": "Forensic Psychiatry",
        "value": "Forensic Psychiatry"
    },
    {
        "label": "Gastro-enterology",
        "value": "Gastro-enterology"
    },
    {
        "label": "Gastrointestinal and Urodynamic Sciences",
        "value": "Gastrointestinal and Urodynamic Sciences"
    },
    {
        "label": "Gastrointestinal Pathology Reporting",
        "value": "Gastrointestinal Pathology Reporting"
    },
    {
        "label": "Gastrointestinal Physiology",
        "value": "Gastrointestinal Physiology"
    },
    {
        "label": "General Acute",
        "value": "General Acute"
    },
    {
        "label": "General Medicine",
        "value": "General Medicine"
    },
    {
        "label": "General Pathology",
        "value": "General Pathology"
    },
    {
        "label": "General Practice",
        "value": "General Practice"
    },
    {
        "label": "General Psychiatry",
        "value": "General Psychiatry"
    },
    {
        "label": "General Surgery",
        "value": "General Surgery"
    },
    {
        "label": "Genetic Counselling",
        "value": "Genetic Counselling"
    },
    {
        "label": "Genito-Urinary Medicine",
        "value": "Genito-Urinary Medicine"
    },
    {
        "label": "Genomics",
        "value": "Genomics"
    },
      {
          "label": "Grounds and Gardens",
          "value": "Grounds and Gardens"
      },
      {
          "label": "Gynaecological Oncology",
          "value": "Gynaecological Oncology"
      },
      {
          "label": "Gynaecological Pathology Reporting",
          "value": "Gynaecological Pathology Reporting"
      },
      {
          "label": "Gynaecology",
          "value": "Gynaecology"
      },
      {
          "label": "Haematology",
          "value": "Haematology"
      },
      {
          "label": "Haemato-oncology",
          "value": "Haemato-oncology"
      },
      {
          "label": "Haemostasis and Thrombosis",
          "value": "Haemostasis and Thrombosis"
      },
      {
          "label": "Head and Neck Surgery",
          "value": "Head and Neck Surgery"
      },
      {
          "label": "Health and Safety",
          "value": "Health and Safety"
      },
      {
          "label": "Health Advocacy",
          "value": "Health Advocacy"
      },
      {
          "label": "Health Informatics Science",
          "value": "Health Informatics Science"
      },
      {
          "label": "Health Promotion",
          "value": "Health Promotion"
      },
      {
          "label": "Healthcare Science",
          "value": "Healthcare Science"
      },
      {
          "label": "Health Visiting",
          "value": "Health Visiting"
      },
      {
          "label": "Hepatology",
          "value": "Hepatology"
      },
      {
          "label": "Histocompatibility and Immunogenetics",
          "value": "Histocompatibility and Immunogenetics"
      },
      {
          "label": "Histological Dissection",
          "value": "Histological Dissection"
      },
      {
          "label": "Histopathology",
          "value": "Histopathology"
      },
      {
          "label": "Human Resources",
          "value": "Human Resources"
      },
      {
          "label": "Imaging",
          "value": "Imaging"
      },
      {
          "label": "Immunology",
          "value": "Immunology"
      },
      {
          "label": "Infection Sciences",
          "value": "Infection Sciences"
      },
      {
          "label": "Infectious Diseases",
          "value": "Infectious Diseases"
      },
      {
          "label": "Informatics",
          "value": "Informatics"
      },
      {
          "label": "Integrated Urgent Care",
          "value": "Integrated Urgent Care"
      },
      {
          "label": "Intensive Care",
          "value": "Intensive Care"
      },
      {
          "label": "Intensive Care Medicine",
          "value": "Intensive Care Medicine"
      },
      {
          "label": "Intermediate Care",
          "value": "Intermediate Care"
      },
      {
          "label": "Ionising Radiation",
          "value": "Ionising Radiation"
      },
      {
          "label": "Kitchen",
          "value": "Kitchen"
      },
      {
          "label": "Knowledge and Library Management",
          "value": "Knowledge and Library Management"
      },
      {
          "label": "Laundry",
          "value": "Laundry"
      },
      {
          "label": "Learning Disability Mental Health",
          "value": "Learning Disability Mental Health"
      },
      {
          "label": "Learning Disability Other",
          "value": "Learning Disability Other"
      },
      {
          "label": "Legal",
          "value": "Legal"
      },
      {
          "label": "Liaison Psychiatry",
          "value": "Liaison Psychiatry"
      },
      {
          "label": "Linen Services",
          "value": "Linen Services"
      },
      {
          "label": "MacMillan Nursing",
          "value": "MacMillan Nursing"
      },
      {
          "label": "Mammography",
          "value": "Mammography"
      },
      {
          "label": "Marie Curie Nursing",
          "value": "Marie Curie Nursing"
      },
      {
          "label": "Maternal and Fetal Medicine",
          "value": "Maternal and Fetal Medicine"
      },
      {
          "label": "Maternity",
          "value": "Maternity"
      },
      {
          "label": "Maxillofacial Prosthetics",
          "value": "Maxillofacial Prosthetics"
      },
      {
          "label": "Medical Engineering Design",
          "value": "Medical Engineering Design"
      },
      {
          "label": "Medical Equipment Management",
          "value": "Medical Equipment Management"
      },
      {
          "label": "Medical Illustration",
          "value": "Medical Illustration"
      },
      {
          "label": "Medical Microbiology",
          "value": "Medical Microbiology"
      },
      {
          "label": "Medical Microbiology and Virology",
          "value": "Medical Microbiology and Virology"
      },
      {
          "label": "Medical Oncology",
          "value": "Medical Oncology"
      },
      {
          "label": "Medical Ophthalmology",
          "value": "Medical Ophthalmology"
      },
      {
          "label": "Medical Physics",
          "value": "Medical Physics"
      },
      {
          "label": "Medical Physics Computing",
          "value": "Medical Physics Computing"
      },
      {
          "label": "Medical Psychotherapy",
          "value": "Medical Psychotherapy"
      },
      {
          "label": "Medical Staffing",
          "value": "Medical Staffing"
      },
      {
          "label": "Medical Virology",
          "value": "Medical Virology"
      },
      {
          "label": "Medicine",
          "value": "Medicine"
      },
        {
            "label": "Mental and Physical Health Integrated Care",
            "value": "Mental and Physical Health Integrated Care"
        },
        {
            "label": "Mental Health",
            "value": "Mental Health"
        },
        {
            "label": "Mental Health Adult",
            "value": "Mental Health Adult"
        },
        {
            "label": "Mental Health Child and Adolescent",
            "value": "Mental Health Child and Adolescent"
        },
        {
            "label": "Mental Health - Community",
            "value": "Mental Health - Community"
        },
        {
            "label": "Mental Health Crisis Care and Home Treatment Team",
            "value": "Mental Health Crisis Care and Home Treatment Team"
        },
        {
            "label": "Mental Health Eating Disorders",
            "value": "Mental Health Eating Disorders"
        },
        {
            "label": "Mental Health Early Intervention in Psychosis",
            "value": "Mental Health Early Intervention in Psychosis"
        },
        {
            "label": "Mental Health Forensic",
            "value": "Mental Health Forensic"
        },
        {
            "label": "Mental Health Liaison",
            "value": "Mental Health Liaison"
        },
        {
            "label": "Mental Health Older People",
            "value": "Mental Health Older People"
        },
        {
            "label": "Mental Health Perinatal Care",
            "value": "Mental Health Perinatal Care"
        },
        {
            "label": "Mental Health Primary Care",
            "value": "Mental Health Primary Care"
        },
        {
            "label": "Mental Health Primary Care -- NHS Talking Therapies",
            "value": "Mental Health Primary Care -- NHS Talking Therapies"
        },
        {
            "label": "Mental Health Promotion or Public Mental Health",
            "value": "Mental Health Promotion or Public Mental Health"
        },
        {
            "label": "Mental Health Substance Misuse",
            "value": "Mental Health Substance Misuse"
        },
        {
            "label": "Metabolic Medicine",
            "value": "Metabolic Medicine"
        },
        {
            "label": "Molecular Genetics",
            "value": "Molecular Genetics"
        },
        {
            "label": "Molecular Pathology",
            "value": "Molecular Pathology"
        },
        {
            "label": "MRI",
            "value": "MRI"
        },
        {
            "label": "Musculoskeletal",
            "value": "Musculoskeletal"
        },
        {
            "label": "Music Therapy",
            "value": "Music Therapy"
        },
        {
            "label": "Neonatal Intensive Care",
            "value": "Neonatal Intensive Care"
        },
        {
            "label": "Neurology",
            "value": "Neurology"
        },
        {
            "label": "Neuropathology",
            "value": "Neuropathology"
        },
        {
            "label": "Neurophysiology",
            "value": "Neurophysiology"
        },
        {
            "label": "Neurorehabilitation",
            "value": "Neurorehabilitation"
        },
        {
            "label": "Neurosensory Sciences",
            "value": "Neurosensory Sciences"
        },
        {
            "label": "Neurosurgery",
            "value": "Neurosurgery"
        },
        {
            "label": "NHS 111",
            "value": "NHS 111"
        },
        {
            "label": "Nights",
            "value": "Nights"
        },
        {
            "label": "Non-Emergency Control",
            "value": "Non-Emergency Control"
        },
        {
            "label": "Non-Ionising Radiation",
            "value": "Non-Ionising Radiation"
        },
        {
            "label": "Nuclear Medicine",
            "value": "Nuclear Medicine"
        },
        {
            "label": "Nutritional Sciences",
            "value": "Nutritional Sciences"
        },
        {
            "label": "Obstetrics",
            "value": "Obstetrics"
        },
        {
            "label": "Obstetrics and Gynaecology",
            "value": "Obstetrics and Gynaecology"
        },
        {
            "label": "Occupational Health",
            "value": "Occupational Health"
        },
        {
            "label": "Occupational Therapy",
            "value": "Occupational Therapy"
        },
        {
            "label": "Old Age Psychiatry",
            "value": "Old Age Psychiatry"
        },
        {
            "label": "Operating Department",
            "value": "Operating Department"
        },
        {
            "label": "Ophthalmic and Vision Science",
            "value": "Ophthalmic and Vision Science"
        },
        {
            "label": "Ophthalmology",
            "value": "Ophthalmology"
        },
        {
            "label": "Optometry",
            "value": "Optometry"
        },
        {
            "label": "Oral and Maxillofacial Surgery",
            "value": "Oral and Maxillofacial Surgery"
        },
        {
            "label": "Oral and Maxillofacial Pathology",
            "value": "Oral and Maxillofacial Pathology"
        },
        {
            "label": "Oral Hygiene",
            "value": "Oral Hygiene"
        },
        {
            "label": "Oral Medicine",
            "value": "Oral Medicine"
        },
        {
            "label": "Oral Microbiology",
            "value": "Oral Microbiology"
        },
        {
            "label": "Oral Surgery",
            "value": "Oral Surgery"
        },
        {
            "label": "Orthodontics",
            "value": "Orthodontics"
        },
        {
            "label": "Orthoptics",
            "value": "Orthoptics"
        },
        {
            "label": "Otolaryngology",
            "value": "Otolaryngology"
        },
        {
            "label": "Outpatients",
            "value": "Outpatients"
        },
        {
            "label": "Paediatric Accident and Emergency",
            "value": "Paediatric Accident and Emergency"
        },
        {
            "label": "Paediatric and Perinatal Pathology",
            "value": "Paediatric and Perinatal Pathology"
        },
        {
            "label": "Paediatric Cardiology",
            "value": "Paediatric Cardiology"
        },
        {
            "label": "Paediatric Clinical Pharmacology",
            "value": "Paediatric Clinical Pharmacology"
        },
        {
            "label": "Paediatric Community Child Health",
            "value": "Paediatric Community Child Health"
        },
        {
            "label": "Paediatric Dentistry",
            "value": "Paediatric Dentistry"
        },
        {
            "label": "Paediatric Endocrinology",
            "value": "Paediatric Endocrinology"
        },
        {
            "label": "Paediatric Gastroenterology",
            "value": "Paediatric Gastroenterology"
        },
        {
            "label": "Paediatric Infectious Diseases and Immunology",
            "value": "Paediatric Infectious Diseases and Immunology"
        },
        {
            "label": "Paediatric Intensive Care Medicine",
            "value": "Paediatric Intensive Care Medicine"
        },
        {
            "label": "Paediatric Neonatal Medicine",
            "value": "Paediatric Neonatal Medicine"
        },
        {
            "label": "Paediatric Nephrology",
            "value": "Paediatric Nephrology"
        },
        {
            "label": "Paediatric Neurology",
            "value": "Paediatric Neurology"
        },
        {
            "label": "Paediatric Oncology",
            "value": "Paediatric Oncology"
        },
        {
            "label": "Paediatric Pathology",
            "value": "Paediatric Pathology"
        },
        {
            "label": "Paediatric Respiratory Medicine",
            "value": "Paediatric Respiratory Medicine"
        },
        {
            "label": "Paediatric Rheumatology",
            "value": "Paediatric Rheumatology"
        },
        {
            "label": "Paediatric Surgery",
            "value": "Paediatric Surgery"
        },
        {
            "label": "Paediatrics",
            "value": "Paediatrics"
        },
        {
            "label": "Pain Management",
            "value": "Pain Management"
        },
        {
            "label": "Palliative Medicine",
            "value": "Palliative Medicine"
        },
        {
            "label": "Patient Record and Coding Services",
            "value": "Patient Record and Coding Services"
        },
        {
            "label": "Pathology",
            "value": "Pathology"
        },
        {
            "label": "Pathology External Quality Assurance",
            "value": "Pathology External Quality Assurance"
        },
        {
            "label": "Patient Safety",
            "value": "Patient Safety"
        },
        {
            "label": "Patient Transport Services",
            "value": "Patient Transport Services"
        },
        {
            "label": "Payroll",
            "value": "Payroll"
        },
        {
            "label": "Performance Management",
            "value": "Performance Management"
        },
        {
            "label": "Periodontics",
            "value": "Periodontics"
        },
        {
            "label": "Pharmaceutical Medicine",
            "value": "Pharmaceutical Medicine"
        },
        {
            "label": "Pharmacy",
            "value": "Pharmacy"
        },
          {
              "label": "Phlebotomy",
              "value": "Phlebotomy"
          },
          {
              "label": "Physiotherapy",
              "value": "Physiotherapy"
          },
          {
              "label": "Plastic Surgery",
              "value": "Plastic Surgery"
          },
          {
              "label": "Point of Care Testing",
              "value": "Point of Care Testing"
          },
          {
              "label": "Portering Services",
              "value": "Portering Services"
          },
          {
              "label": "Primary Care",
              "value": "Primary Care"
          },
          {
              "label": "Private Patients",
              "value": "Private Patients"
          },
          {
              "label": "Prosthetics and Orthotics",
              "value": "Prosthetics and Orthotics"
          },
          {
              "label": "Prosthodontics",
              "value": "Prosthodontics"
          },
          {
              "label": "Psychiatry",
              "value": "Psychiatry"
          },
          {
              "label": "Psychiatry of Learning Disability",
              "value": "Psychiatry of Learning Disability"
          },
          {
              "label": "Public Health",
              "value": "Public Health"
          },
          {
              "label": "Public Health Dentistry",
              "value": "Public Health Dentistry"
          },
          {
              "label": "Public Health Medicine",
              "value": "Public Health Medicine"
          },
          {
              "label": "Public Health Sciences",
              "value": "Public Health Sciences"
          },
          {
              "label": "Purchasing and Supplies",
              "value": "Purchasing and Supplies"
          },
          {
              "label": "Quality and Patient Care",
              "value": "Quality and Patient Care"
          },
          {
              "label": "Radiation Engineering",
              "value": "Radiation Engineering"
          },
          {
              "label": "Radiation Safety",
              "value": "Radiation Safety"
          },
          {
              "label": "Radiation Sciences",
              "value": "Radiation Sciences"
          },
          {
              "label": "Radiotherapy Patient Review",
              "value": "Radiotherapy Patient Review"
          },
          {
              "label": "Radiopharmacy",
              "value": "Radiopharmacy"
          },
          {
              "label": "Radiotherapy Pre-Treatment or Planning",
              "value": "Radiotherapy Pre-Treatment or Planning"
          },
          {
              "label": "Radiotherapy Quality Management or Qual Assurance",
              "value": "Radiotherapy Quality Management or Qual Assurance"
          },
          {
              "label": "Radiotherapy Research",
              "value": "Radiotherapy Research"
          },
          {
              "label": "Radiotherapy",
              "value": "Radiotherapy"
          },
          {
              "label": "Radiotherapy Physics",
              "value": "Radiotherapy Physics"
          },
          {
              "label": "Reconstructive Science",
              "value": "Reconstructive Science"
          },
          {
              "label": "Rehabilitation",
              "value": "Rehabilitation"
          },
          {
              "label": "Rehabilitation Engineering",
              "value": "Rehabilitation Engineering"
          },
          {
              "label": "Rehabilitation Psychiatry",
              "value": "Rehabilitation Psychiatry"
          },
          {
              "label": "Renal Technology",
              "value": "Renal Technology"
          },
          {
              "label": "Renal Medicine",
              "value": "Renal Medicine"
          },
          {
              "label": "Reproductive Sciences",
              "value": "Reproductive Sciences"
          },
          {
              "label": "Research and Development",
              "value": "Research and Development"
          },
          {
              "label": "Respiratory Medicine",
              "value": "Respiratory Medicine"
          },
          {
              "label": "Respiratory Physiology",
              "value": "Respiratory Physiology"
          },
          {
              "label": "Restorative Dentistry",
              "value": "Restorative Dentistry"
          },
          {
              "label": "Rheumatology",
              "value": "Rheumatology"
          },
          {
              "label": "School Nursing",
              "value": "School Nursing"
          },
          {
              "label": "Security",
              "value": "Security"
          },
          {
              "label": "Service Planning",
              "value": "Service Planning"
          },
          {
              "label": "Sewing Room",
              "value": "Sewing Room"
          },
          {
              "label": "Sexual and Reproductive Health",
              "value": "Sexual and Reproductive Health"
          },
          {
              "label": "Sexual and Reproductive Medicine",
              "value": "Sexual and Reproductive Medicine"
          },
          {
              "label": "Simulation Technology",
              "value": "Simulation Technology"
          },
          {
              "label": "Sleep Physiology",
              "value": "Sleep Physiology"
          },
          {
              "label": "Social Care",
              "value": "Social Care"
          },
          {
              "label": "Social Sciences",
              "value": "Social Sciences"
          },
          {
              "label": "Social Services",
              "value": "Social Services"
          },
          {
              "label": "Special Care Dentistry",
              "value": "Special Care Dentistry"
          },
          {
              "label": "Speech and Language Therapy",
              "value": "Speech and Language Therapy"
          },
          {
              "label": "Sport and Exercise Medicine",
              "value": "Sport and Exercise Medicine"
          },
          {
              "label": "Staff Facilities",
              "value": "Staff Facilities"
          },
          {
              "label": "Sterile Services",
              "value": "Sterile Services"
          },
          {
              "label": "Stoma Care",
              "value": "Stoma Care"
          },
          {
              "label": "Stroke",
              "value": "Stroke"
          },
          {
              "label": "Substance Misuse Psychiatry",
              "value": "Substance Misuse Psychiatry"
          },
          {
              "label": "Surgery",
              "value": "Surgery"
          },
          {
              "label": "Sustainability",
              "value": "Sustainability"
          },
          {
              "label": "Teenage Pregnancy",
              "value": "Teenage Pregnancy"
          },
          {
              "label": "Telephone Services",
              "value": "Telephone Services"
          },
          {
              "label": "Tissue Banking",
              "value": "Tissue Banking"
          },
          {
              "label": "Toxicology",
              "value": "Toxicology"
          },
          {
              "label": "Training",
              "value": "Training"
          },
          {
              "label": "Transport",
              "value": "Transport"
          },
          {
              "label": "Trauma and Orthopaedic Surgery",
              "value": "Trauma and Orthopaedic Surgery"
          },
          {
              "label": "Tropical Medicine",
              "value": "Tropical Medicine"
          },
          {
              "label": "Ultrasound",
              "value": "Ultrasound"
          },
          {
              "label": "Urgent Treatment Centre",
              "value": "Urgent Treatment Centre"
          },
          {
              "label": "Urodynamics",
              "value": "Urodynamics"
          },
          {
              "label": "Urogynaecology",
              "value": "Urogynaecology"
          },
          {
              "label": "Urology",
              "value": "Urology"
          },
          {
              "label": "Vascular Medicine",
              "value": "Vascular Medicine"
          },
          {
              "label": "Vascular Surgery",
              "value": "Vascular Surgery"
          },
          {
              "label": "Vascular Science",
              "value": "Vascular Science"
          },
          {
              "label": "Voluntary Services",
              "value": "Voluntary Services"
          },
          {
              "label": "Waste Management",
              "value": "Waste Management"
          },
          {
              "label": "Wound Management",
              "value": "Wound Management"
          }
      
    
  

]
export const jobType = [
  { label: 'Employee', value: 'Employee' },
  { label: 'Temporary / Contract', value: 'Temporary / Contract' },
  { label: 'Intern', value: 'Intern' },
  { label: 'Seasonal', value: 'Seasonal' },
  { label: 'Volunteer', value: 'Volunteer' },
  { label: 'CCL Sponsored', value: 'CCL Sponsored' },
  { label: 'Temporary', value: 'Temporary' },
];

export const monsterDuration = [
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
]

export const industryType = [
  { label: "All", value: "All" },
  { label: "Agriculture/Forestry/Fishing", value: "Agriculture/Forestry/Fishing" },
  { label: "Metals and Minerals", value: "Metals and Minerals" },
  { label: "Energy and Utilities", value: "Energy and Utilities" },
  { label: "Construction - Industrial Facilities and Infrastructure", value: "Construction - Industrial Facilities and Infrastructure" },
  { label: "Aerospace and Defense", value: "Aerospace and Defense" },
  { label: "Automotive and Parts Mfg", value: "Automotive and Parts Mfg" },
  { label: "Biotechnology/Pharmaceuticals", value: "Biotechnology/Pharmaceuticals" },
  { label: "Chemicals/Petro-Chemicals", value: "Chemicals/Petro-Chemicals" },
  { label: "Consumer Packaged Goods Manufacturing", value: "Consumer Packaged Goods Manufacturing" },
  { label: "Electronics, Components, and Semiconductor Mfg", value: "Electronics, Components, and Semiconductor Mfg" },
  { label: "Manufacturing - Other", value: "Manufacturing - Other" },
  { label: "Printing and Publishing", value: "Printing and Publishing" },
  { label: "Clothing and Textile Manufacturing", value: "Clothing and Textile Manufacturing" },
  { label: "Wholesale Trade/Import-Export", value: "Wholesale Trade/Import-Export" },
  { label: "Retail", value: "Retail" },
  { label: "Travel, Transportation and Tourism", value: "Travel, Transportation and Tourism" },
  { label: "Transport and Storage - Materials", value: "Transport and Storage - Materials" },
  { label: "Internet Services", value: "Internet Services" },
  { label: "Broadcasting, Music, and Film", value: "Broadcasting, Music, and Film" },
  { label: "Telecommunications Services", value: "Telecommunications Services" },
  { label: "Banking", value: "Banking" },
  { label: "Insurance", value: "Insurance" },
  { label: "Real Estate/Property Management", value: "Real Estate/Property Management" },
  { label: "Rental Services", value: "Rental Services" },
  { label: "Accounting and Auditing Services", value: "Accounting and Auditing Services" },
  { label: "Advertising and PR Services", value: "Advertising and PR Services" },
  { label: "Architectural and Design Services", value: "Architectural and Design Services" },
  { label: "Management Consulting Services", value: "Management Consulting Services" },
  { label: "Computer Hardware", value: "Computer Hardware" },
  { label: "Computer Software", value: "Computer Software" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Waste Management", value: "Waste Management" },
  { label: "Education", value: "Education" },
  { label: "Healthcare Services", value: "Healthcare Services" },
  { label: "Performing and Fine Arts", value: "Performing and Fine Arts" },
  { label: "Sports and Physical Recreation", value: "Sports and Physical Recreation" },
  { label: "Hotels and Lodging", value: "Hotels and Lodging" },
  { label: "Restaurant/Food Services", value: "Restaurant/Food Services" },
  { label: "Staffing/Employment Agencies", value: "Staffing/Employment Agencies" },
  { label: "Nonprofit Charitable Organizations", value: "Nonprofit Charitable Organizations" },
  { label: "Personal and Household Services", value: "Personal and Household Services" },
  { label: "Government and Military", value: "Government and Military" },
  { label: "Security and Surveillance", value: "Security and Surveillance" },
  { label: "Automotive Sales and Repair Services", value: "Automotive Sales and Repair Services" },
  { label: "Business Services - Other", value: "Business Services - Other" },
  { label: "Computer/IT Services", value: "Computer/IT Services" },
  { label: "Construction - Residential & Commercial/Office", value: "Construction - Residential & Commercial/Office" },
  { label: "Engineering Services", value: "Engineering Services" },
  { label: "Entertainment Venues and Theaters", value: "Entertainment Venues and Theaters" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Food and Beverage Production", value: "Food and Beverage Production" },
  { label: "Marine Mfg & Services", value: "Marine Mfg & Services" },
  { label: "Medical Devices and Supplies", value: "Medical Devices and Supplies" },
  { label: "Other/Not Classified", value: "Other/Not Classified" }
];

export const displayError = (response) => {
    try {
        const errorData = response?.additionalInfo?.error;

        if (!errorData) {
            return "No error information available.";
        }

        if (typeof errorData === "object") {
            return JSON.stringify(errorData, null, 2); // Pretty-print if it's an object
        }

        if (typeof errorData === "string") {
            try {
                const parsedError = JSON.parse(errorData);
                return JSON.stringify(parsedError, null, 2);
            } catch {
                return errorData; // Return as is if it's not valid JSON
            }
        }

        return "Unexpected error format.";
    } catch (err) {
        return "An error occurred while processing the error response.";
    }
};



export const postingError = (jobBoard, failedResp) => {
  if (!failedResp?.length) return false;

  const errorMessage = failedResp.find(item => item?.accessKey === jobBoard);
  if (!errorMessage) return false;

  return errorMessage?.postingDetails?.data?.message || JSON.stringify(errorMessage?.postingDetails?.data);
};

export const bandTypeHospital =
  [{ label: 'Specialty doctor', value: 'Specialty doctor' },
  { label: 'Foundation doctor', value: 'Foundation doctor' },
  { label: 'Conultant', value: 'Conultant' },
  { label: 'Specialty registrar', value: 'Specialty registrar' },
  { label: 'Doctor other', value: 'Doctor other' },
  { label: 'Specialist', value: 'Specialist' }
];

  export const bandTypeAgenda =
  [{ label: 'Band 2', value: 'Band 2' },
  { label: 'Band 3', value: 'Band 3' },
  { label: 'Band 4', value: 'Band 4' },
  { label: 'Band 5', value: 'Band 5' },
  { label: 'Band 6', value: 'Band 6' },
  { label: 'Band 7', value: 'Band 7' },
  { label: 'Band 8A', value: 'Band 8A' },
  { label: 'Band 8B', value: 'Band 8B' },
  { label: 'Band 8C', value: 'Band 8C' },
  { label: 'Band 8D', value: 'Band 8D' },
  { label: 'Band 9', value: 'Band 9' }
];

export const advertiseType = [
  { label: 'Internally', value: 'Internally' },
  { label: 'Externally', value: 'Externally' },
  { label: 'Group Internal', value: 'Group Internal' }
];

export const contractType = [
  { label: 'Permanent', value: 'Permanent' },
  { label: 'Fixed-term', value: 'Fixed-term' },
  { label: 'Locum', value: 'Locum' },
  { label: 'Training', value: 'Training' },
  { label: 'Apprenticeship', value: 'Apprenticeship' },
  { label: 'Secondment', value: 'Secondment' },
  { label: 'Voluntary', value: 'Voluntary' },
  { label: 'Honorary', value: 'Honorary' },
  { label: 'Bank', value: 'Bank' },
  { label: 'NHS Reservist', value: 'NHS Reservist' }
];

export const vacancyStaffGroup = [
  { label: 'Clinical services', value: 'Clinical services' },
  { label: 'Professional scientific and technical', value: 'Professional scientific and technical' },
  { label: 'Administrative and clerical', value: 'Administrative and clerical' },
  { label: 'Allied health professionals', value: 'Allied health professionals' },
  { label: 'Estates and ancillary', value: 'Estates and ancillary' },
  { label: 'Healthcare scientists', value: 'Healthcare scientists' },
  { label: 'Medical and dental', value: 'Medical and dental' },
  { label: 'Nursing and midwifery registered', value: 'Nursing and midwifery registered' },
  { label: 'Students', value: 'Students' }
]; 