import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useField } from 'formik';
import styled from 'styled-components';

const LocationSearchContainer = styled.div`
  position: relative;
  width: 100%;
    input[type=text]{
  font-size:12px !important;}
`;

const LocationSearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme, hasError }) => (hasError ? 'red' : theme.borderLight)};
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? 'red' : theme.inputBorder)};
  }
`;


const AutocompleteDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  outline:none;
  border: none;
  border-radius: 4px;
  background: ${({theme}) => theme.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
   &:focus {
      border: 1px solid ${({theme}) => theme.inputBorder};
      background: ${({theme}) => theme.primaryLight};
      color: ${({theme}) => theme.primary};
      box-shadow: none;
    }
`;

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.primaryLight};
  }
`;

const SuggestionItemActive = styled(SuggestionItem)`
  background-color: ${({ theme }) => theme.primaryLight};
`;

const Loading = styled.div`
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
`;

const Error = styled.div`
  color: red;
  margin-top: 5px;
`;

const FormikGoogleLocation = ({ name, placeholder, onChange,type }) => {
  const [field, meta, helpers] = useField(name);
  const [address, setAddress] = useState(field.value?.formatted_address || '');
  useEffect(() => {
    if (type === 'analytics' && field?.value?.formatted_address) {
      setAddress(field.value.formatted_address);
    }
}, [field.value]);
  const handleChange = (address) => {
    setAddress(address);
    if(type !== 'analytics'){
      helpers.setValue(address); // Update Formik's value state
      if (onChange) {
        onChange(address); // Trigger external onChange
      }
    }
  };

  const handleSelect = (address) => {
    setAddress(address);

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const fullLocationObject = results[0];
        helpers.setValue(fullLocationObject);
        if (onChange) {
          onChange(fullLocationObject); // Trigger external onChange
        }
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  return (
    <LocationSearchContainer>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <LocationSearchInput
              {...getInputProps({
                placeholder: placeholder || 'Search Places...',
              })}
              hasError={meta.touched && meta.error}
            />
            <AutocompleteDropdownContainer>
              {loading && <Loading>Loading...</Loading>}
              {suggestions.map((suggestion) => (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style: suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' },
                  })}
                >
                  {suggestion.active ? (
                    <SuggestionItemActive>{suggestion.description}</SuggestionItemActive>
                  ) : (
                    <SuggestionItem>{suggestion.description}</SuggestionItem>
                  )}
                </div>
              ))}
            </AutocompleteDropdownContainer>
          </div>
        )}
      </PlacesAutocomplete>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </LocationSearchContainer>
  );
};


export default FormikGoogleLocation;