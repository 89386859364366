import React from 'react';
import styled from 'styled-components';
import { InterviewStatus,campaignTabfiltersKeys, filterSelectedPlaceholder, filterSelectedPrefix, jobTabfiltersKeys, ratings } from '../../assets/analyticsUtil';
import { isArray, isDate, isEmpty } from 'lodash';
import moment from 'moment';
import { toTitleCase } from '../../components/HiringUtils';
import theme from '../../../../utils/themes';
import CrossIcon from '../../../../app/components/svgIcons/CrossIcon';
import { filterLocation, getStatusFriendly } from '../../../../utils/helper';
import { getAddressStringByGoogleLocation } from './jobCreationUtil';

export const FiltersTiles = ({ filters, clearField, tab }) => {
  const selectedSingleValues = (key, value) => {
    switch (key) {
      case 'status':
      case 'campaignStatus':
        return getStatusFriendly(filters[key]);
      case 'budgeted':
        return value === 'yes' ? 'Budgeted' : 'Non Budgeted';
      case 'isCompetitive':
        return value === 'yes' ? 'Competitive Salary' : 'Not Competitive Salary';
      case 'interviewScheduled':
        return value === 'yes' ? 'Scheduled Interview' : 'Not Scheduled Interview';
      case 'rejected':
        return value === 'yes' ? 'Rejected Candidates' : 'Unrejected  Candidates';
      case 'jobLevel':
      case 'employmentType':
      case 'remoteStatus':
      case 'copywriteStatus':
        return toTitleCase(value, '_');
      case 'rating':
         return ratings.find(item=>item.value===value)?.label;
      case 'interviewStatus':
        return InterviewStatus.find(item=>item.value===value)?.label;
      default:
        return value;
    }
  };

  const isTabFilter=(key)=> tab === 3 ? jobTabfiltersKeys.includes(key) : tab === 4 ? campaignTabfiltersKeys.includes(key) : true

  return (
    <StyledWrap className="d-flex w-100 cursor-pointer">
      {Object.keys(filters)?.map(
        (key) =>
          (!isEmpty(filters[key]) || ( typeof filters[key] ==='number' && filters[key] > 0 ) ||  isDate(filters[key])) && isTabFilter(key)  ? (
            <StyledTile>
              {`${filterSelectedPrefix[key] || ''}${
                isArray(filters[key])
                  ? filters[key]?.length || 0
                  : typeof filters[key] === 'object' && key === 'address' 
                  ?  getAddressStringByGoogleLocation(filterLocation(filters[key]),['address','postalTown', 'postalCode','country'])
                  : typeof filters[key] === 'object' && key === 'candPostcode' 
                  ?  getAddressStringByGoogleLocation(filterLocation(filters[key]),['postalTown', 'postalCode'])
                  : typeof filters[key] === 'object' 
                  ? filters[key]?.label || (moment(filters[key]).isValid() && moment(filters[key]).format('Do MMM YYYY'))
                  : selectedSingleValues(key, filters[key])
              } ${filterSelectedPlaceholder[key] || ''}`}
              <CrossIcon size={12} onClick={() => clearField(key)} />
            </StyledTile>
          ):null,
      )}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  gap: 12px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTile = styled.div`
  background: ${theme.interviewAttend};
  padding: 2px 4px;
  display: flex;
  align-items: center;
  color: ${theme.blue};
  font-weight: 600;
  letter-spacing: 0.24px;
  font-size: 12px;
  gap: 4px;
  border-radius: 4px;
  margin-bottom: 12px;
`;
